@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  color-scheme: dark !important;
}

@layer components {
}
/* input[type="text"],
textarea {
  background-color: rgba(0, 0, 0, 0) !important;
} */

/* textarea {
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
textarea:focus {
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
input {
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
input:focus {
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
} */

#progress-bar {
  --scrollAmount: 0%;
  width: var(--scrollAmount);
  position: fixed;
  top: 88px;
}

/* DO NOT REMOVE */
.control-dots {
  z-index: -1 !important;
}
.carousel.carousel-slider {
  overflow: visible !important;
}
.carousel .slider-wrapper {
  overflow: visible !important;
}

.disable .remirror-theme .ProseMirror {
  min-height: 20px !important;
  overflow: hidden; /* Hide scrollbars */
}

.remirror-theme .ProseMirror {
  overflow: hidden !important; /* Hide scrollbars */
}

pre {
  margin: 0 !important;
  padding: 0 !important;
}

code {
  width: 100% !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Warning: react-select is a pain and this is the best way to override styles by far. */
.tc__control {
  @apply border-gray-200 bg-transparent text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-950 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:cursor-not-allowed disabled:opacity-50 !important;
}

.tc__control--is-focused {
  @apply ring-1 ring-gray-950 disabled:cursor-not-allowed disabled:opacity-50 !important;
}

.tc__menu {
  @apply bg-white text-sm border border-gray-950 !important;
}

.tc__input-container {
  @apply text-gray-950 text-sm !important;
}

.tc__input {
  @apply text-gray-950 text-sm !important;
}

.tc__single-value {
  @apply text-gray-950 text-sm !important;
}

.tc__multi-value {
  @apply text-gray-950 bg-white border border-gray-500 rounded-md text-sm !important;
}

.tc__multi-value__label {
  @apply text-gray-950 text-sm !important;
}

/* Warning: react-select is a pain and this is the best way to override styles by far. */
.gh__control {
  @apply border-transparent bg-transparent text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:cursor-not-allowed disabled:opacity-50 !important;
}

.gh__control--is-focused {
  @apply ring-0 ring-gray-950 disabled:cursor-not-allowed disabled:opacity-50 !important;
}

.gh__menu {
  @apply text-sm !important;
}

@layer base {
  :root {
    --radius: 0.5rem;
  }
}

.filepond--panel-root {
  @apply border border-gray-200 border-dotted bg-transparent !important;
}

.remirror-suggest-atom {
  @apply border border-gray-200 border-dotted bg-transparent text-gray-500 !important;
}
